import { gql } from "@apollo/client";
export default gql`
  query {
    me {
      username
      email
      firstName
      avatar {
        name
        width
        height
        url
      }
    }
  }
`;
